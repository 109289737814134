<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div v-else>
    <div class="page-progress-wrap">
      <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.title : '' }}</h3>
      <div class="progress-box">
        <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[0].image_link: ''" alt="">
        <span>Input</span>
      </div>
      <div class="progress-box">
        <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[1].image_link: ''" alt="">
        <span>Confirm</span>
      </div>
      <div class="done-box">
        <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[2].image_link: ''" alt="">
        <span>Done</span>
      </div>
    </div>
    <form id="form">
      <div class="topup-summery-wrap">
        <ul>
          <li>
            <span>{{ dataDetails.review_title }}</span>
            <a class="btn edit-btn" id="edit-btn" @click="goEditPage" href="javascript:void(0);">{{ dataDetails.edit_btn_text }} </a>
          </li>
          <li>
            <span>{{ dataDetails.amount_text }}</span>
            <h5>{{ dataDetails.currency }} {{ dataDetails.amount }}</h5>
          </li>
          <li v-if="service==='talktime_topup'">
            <span>{{ dataDetails.price_text }}</span>
            <h5>RM {{ dataDetails.package_price }}</h5>
          </li>
          <li>
            <span>{{ dataDetails.receiver_no_text }}</span>
            <h5>{{ dataDetails.receiver_number }}</h5>
          </li>
          <li>
            <span>{{ dataDetails.operator_text }}</span>
            <img class="operator_small" :src="dataDetails.operator" alt="">
          </li>
          <li v-if="service==='internet_topup'">
            <span>{{ dataDetails.package_text }}</span>
            <h5>{{ dataDetails.datapack }}</h5>
          </li>
          <li v-if="operatorId === '2' && dataDetails.service_charge!=='0'">
            <span>{{ dataDetails.service_charge_text }}</span>
            <h5>{{ dataDetails.service_charge }}%</h5>
          </li>
          <li v-if="operatorId === '2' && dataDetails.service_charge!=='0'">
            <span> {{ dataDetails.base_amount_text }}</span>
            <h5>RM {{ dataDetails.total_amount }}</h5>
          </li>
        </ul>
      </div>
      <button type="button" @click="submit" id="next-btn" class="btn justify-center">{{ !_.isEmpty(dataDetails) ? dataDetails.next_btn_text : '' }} <span class="arrow-icon"></span></button>
    </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'
import { gtmDataPush } from '../../../../public/front/js/gtmHepler'
export default {
  name: 'packageReviewPage',
  data () {
    return {
      apiUrl: 'content/',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      receiverNumber: sessionStorage.getItem('receiverMsisdn') ?? '',
      service: '',
      receiverOperatorId: sessionStorage.getItem('receiverOperatorId') ?? '',
      is_loading: true,
      datapackId: sessionStorage.getItem('datapackId') ?? '',
      packagePrice: sessionStorage.getItem('packagePrice') ?? '',
      packageAmount: sessionStorage.getItem('packageAmount') ?? '', // talktime api e amount pathano lage package id er jaygay
      receiverCountry: sessionStorage.getItem('receiverCountry') ?? '',
      priceRmWithCommision: sessionStorage.getItem('priceRmWithCommision') ?? '',
      chargeRate: sessionStorage.getItem('chargeRate'),
      packageName: sessionStorage.getItem('packageName') ?? '',
      msisdn: localStorage.getItem('msisdn') ?? '',
      languageId: sessionStorage.getItem('language') ?? '1',
      sessionId: sessionStorage.getItem('sessionId'),
      operatorId: localStorage.getItem('operatorId'),
      packageBasePrice: sessionStorage.getItem('packageBasePrice')
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      if (this.service === 'talktime_topup') {
        this.apiUrl += 'talktime-package-review-page'
        this.datapackId = this.packageAmount // price ta data pack id hisebe deoya hoice ..or code boro hobe
      } else {
        this.apiUrl += 'package-review-page'
      }
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, {
            operatorId: this.operatorId,
            datapackId: this.datapackId,
            receiverMsisdn: this.receiverNumber,
            receiverOperatorId: this.receiverOperatorId,
            msisdn: this.msisdn,
            languageId: this.languageId,
            packageName: this.packageName,
            priceRmWithCommision: this.priceRmWithCommision,
            packageBasePrice: this.packageBasePrice,
            chargeRate: this.chargeRate
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    submit () {
      gtmDataPush('add_to_cart', this.msisdn, this.receiverNumber, this.packageName ?? 'talk-time', this.operatorId === '1' ? this.packagePrice : (this.priceRmWithCommision ?? this.packageBasePrice))

      if (this.packagePrice == '') {
        sessionStorage.setItem('packagePrice', this.dataDetails.package_price)
      }
      this.$router.push({ name: 'wallet-select-page', params: { service: this.service } })
    },
    goEditPage () {
      this.$router.push({ name: 'topUpList', params: { type: this.service, receiverNumber: this.receiverNumber } })
    }
  }
}
</script>

<style scoped>

</style>
